/** @format */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "../../Repository/Api";
import { View_description } from "../../Helper/Herlper";
import { ImageLazyLoading } from "../../utils/helpingComponent";

const OneNews = () => {
  const { id } = useParams();
  const [response, setResponse] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    getApi({
      url: `api/v1/News/${id}`,
      setResponse,
    });
  }, []);

  const getKeywords = () => {
    if (response?.data?.keyWords) {
      return response?.keyWords?.map(
        (i, index) => i && <button key={`keywords${index}`}> {i} </button>
      );
    }
  };

  return (
    <section
      className="MaxComponent All_News_Page"
      style={{ marginBottom: "40px" }}
    >
      <div className="gap-3  mx-auto  All_News_Page">
        <section className="p-3">
          <div className="my-2">
            <h3 className="my-6 font-medium text-3xl text-primary news-heading">
              {response?.data?.title}
            </h3>
            <ImageLazyLoading
              className={"thumb_image"}
              alt={response?.data?.title}
              img={response?.data?.image}
            />

            <View_description description={response?.data?.description} />
            <div className="keyword_cont">{getKeywords()}</div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default OneNews;
