/** @format */

export const PhoneNumberFormatter = (value) => {
  const number = value;
  const countryCode = number?.slice(0, 1);
  const bracketCode = number?.slice(1, 4);
  const another = number?.slice(4, 7);
  const remaining = number?.slice(7);
  return `+${countryCode}(${bracketCode})${another}-${remaining}`;
};

export const DateOfBirthFormat = (e, setValue) => {
  let value = e.target.value;
  value = value.replace(/\D/g, "");
  if (value.length > 2 && value.length <= 4) {
    value = `${value.slice(0, 2)}/${value.slice(2)}`;
  } else if (value.length > 4) {
    value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
  }
  setValue(value);
};
